<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <img
          src="@/assets/images/tamil-coins.png"
          class="img-fluid"
          alt="Tamil Coins"
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="p-5 text-center">
          <h1 class="mb-3">Blockchain based Tamil language ecosystem.</h1>

          <p>
            The TAMIL Crypto is the world's first Blockchain based technology
            dedicated for the preservation and love of the
            <a
              href="https://www.google.com/search?q=oldest+living+language"
              target="_blank"
              >world's oldest living classical language.</a
            ><br />
            TAMIL Crypto helps to digitilize and decentralize Tamil Language
            assets.
          </p>

          <p>
            It rewards content publishers for their content, node operators and
            validators for enabling and powering decentralized network.
          </p>
        </div>
        <div class="p-1 text-center">
          <h1 class="mb-3">Why "ழ" (ZHA) Symbol?</h1>
          <p class="lead">
            Of the 247 Letters in Tamil alphabets, 'ZHA' is the most
            significant, because of its pronounciation and usage.
          </p>
          <hr class="my-4" />
          <p>
            ழ (ZHA) is the 30th letter of the Tamil alphabet.There are two other
            letters with, similar sound - LA & LLA, But 'ZHA' is very special
            ZHAGARAM because of its unique pronunciation.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>