<template>
  <MDBContainer>
    <div class="tokenCommunity">
      <MDBRow>
        <MDBCol sm="12" md="6" lg="6" class="pb-2">
          <h1><MDBIcon icon="edit" iconStyle="fas" /></h1>
          <h5 class="text-light">Create Your Account</h5>
          <p>Signup to our platform and Add/Create wallet.</p>
          <a href="/dashboard" class="btn btn-light btn-rounded">Signup</a>
        </MDBCol>

        <MDBCol sm="12" md="6" lg="6">
          <h1><MDBIcon icon="credit-card" iconStyle="far" /></h1>
          <h5 class="text-light">TAMIL Crypto - Available Exchanges</h5>
          <p> TAMIL Utility Token has been listed on these decentralized exchanges.</p>

          <a
            href="https://stellarterm.com/exchange/TAMIL-GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM-native"
            target="_blank"
            class="btn btn-light btn-rounded mb-2"
          >
            <i class="fas fa-chart-line"></i> Order Book
          </a>
          <a
            href="/tamiltoken-purchase-steps-interstellar-exchange.pdf"
            target="_blank"
            class="btn btn-light btn-rounded mb-2"
          >
            <i class="fas fa-external-link-alt"></i> Purchase guide Interstellar
            exchange
          </a>

          <a
            href="/tamiltoken-purchase-steps-stellarterm-exchange.pdf"
            target="_blank"
            class="btn btn-light btn-rounded"
          >
            <i class="fas fa-external-link-alt"></i> Purchase guide Stellarterm
            exchange
          </a>
        </MDBCol>
      </MDBRow>
    </div>
  </MDBContainer>
</template>


<script>
import { MDBContainer, MDBCol, MDBRow, MDBIcon } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon,
  },
};
</script>

<style scoped>
.tokenCommunity {
  background-color: #ffa800 !important;
  padding: 20px;
}
</style>
