<template>
  <div class="container-fluid">
    <MDBRow class="gx-5">
      <Symbol />
    </MDBRow>

    <MDBRow mt-5>
      <Steps />
    </MDBRow>

    <MDBRow mt-5>
      <Exchanges />
    </MDBRow>

    <MDBRow mt-5>
      <Community />
    </MDBRow>

    <MDBRow mt-5>
      <MobileApp />
    </MDBRow>
  </div>
</template>

<script>
import { MDBRow } from "mdb-vue-ui-kit";

import Symbol from "@/components/Symbol";
import Exchanges from "@/components/Exchanges";
import Community from "@/components/Community";
import Steps from "@/components/Steps";
import MobileApp from "@/components/MobileApp";


export default {
  name: "Home",
  components: {
    Symbol,
    Exchanges,
    Community,
    Steps,
    MobileApp,
    MDBRow,
  },
  methods: {
    rotateCoin: function() {},
  },
};
</script>

<style scoped>
.container-fluid {
  color: rgb(88, 84, 84);
}
</style>
