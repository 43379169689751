<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <img
          src="@/assets/images/mobile.png"
          class="img-fluid"
          alt="AppStore"
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="pt-5">
          <h1 class="mb-3">Download Mobile App</h1>
          <p>
            Get our mobile app for Android or iOS and take your TAMIL Crypto with
            you wherever you go.
          </p>
        </div>
        <div class="pt-5">
          <a href="/launching-soon">
            <img
              src="@/assets/images/appicon1.svg"
              class="img-fluid hover-shadow mb-2"
              style="max-width: 200px"
              alt="Interstellar"
            />
          </a>
          <a href="/launching-soon">
            <img
              src="@/assets/images/appicon2.svg"
              class="img-fluid hover-shadow mb-2"
              style="max-width: 200px"
              alt="Stellarport"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
