<template>
    <MDBCol>
      <div class="tokenCommunity">
                <h4>TAMIL Crypto Community</h4>
                       Stay updated with us. <br>
                    <p>Follow us on our social media and be the first to know the latest news!</p>
              

                    
                  <a href="https://t.me/tamiltoken" class="btn btn-light btn-rounded">Telegram EN</a>
        </div>
      </MDBCol>
</template>

<script>
  import { MDBCol } from "mdb-vue-ui-kit";

  export default {
    components: {
          MDBCol
         
    }
  };
</script>

<style scoped>
.tokenCommunity {
    background-color: #ffa800 !important;
    padding:20px;
}
</style>