<template>
  <div class="container-fluid pt-4 pb-4">
    <MDBCard text="center" bg="light" class="shadow-5">
      <MDBCardHeader>
        <h2 class="color-light">Exchange Listings</h2>
        <h5 class="color-light">
          The TAMIL Crypto utility token is available on the following decentralized exchanges
        </h5>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBCardText>
          <MDBRow>
            <MDBCol sm="12" md="3" lg="3">
              <a
                href="https://stellarterm.com/exchange/TAMIL-GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM-native"
                target="__blank"
              >
                <img
                  src="@/assets/images/stellarterm.png"
                  class="img-fluid hover-shadow mb-2"
                  style="max-width: 200px"
                  alt="stellarterm"
                />
              </a>
            </MDBCol>

            <MDBCol sm="12" md="3" lg="3">
              <a
                href="https://interstellar.exchange/app/#/trade/guest/TAMIL/GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM/native"
                target="__blank"
              >
                <img
                  src="@/assets/images/interstellar.png"
                  class="img-fluid hover-shadow mb-2"
                  style="max-width: 200px"
                  alt="Interstellar"
                />
              </a>
            </MDBCol>

            <MDBCol sm="12" md="3" lg="3">
              <a href="https://lobstr.co/" target="__blank">
                <img
                  src="@/assets/images/lobstr.png"
                  class="img-fluid hover-shadow mb-2"
                  style="max-width: 200px"
                  alt="Stellarport"
                />
              </a>
            </MDBCol>

            <MDBCol sm="12" md="3" lg="3">
              <a
                href="https://stellarport.io/exchange/GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/TAMIL/native/XLM"
                target="__blank"
              >
                <img
                  src="@/assets/images/stellarport_logo.png"
                  class="img-fluid hover-shadow mb-2"
                  style="max-width: 200px"
                  alt="Stellarport"
                />
              </a>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  </div>
</template>

<script>
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBRow,
} from "mdb-vue-ui-kit";

export default {
  components: {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBCol,
    MDBRow,
  },
};
</script>

<style scoped>
.color-light {
  color: rgb(88, 84, 84);
}
</style>